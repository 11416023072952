import { Stack, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Control, Controller } from 'react-hook-form';

import { HelperText } from '../Inputs/HelperText';

type ControlledDesktopDatePickerTypes = {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any, any>;
    inputFormat: string;
    label: string;
};

export function ControlledDesktopDatePicker({
    control,
    inputFormat,
    label,
    name,
}: ControlledDesktopDatePickerTypes) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={name}
                control={control}
                render={({ field: { ref, value, ...rest }, fieldState: { error } }) => (
                    <DesktopDatePicker
                        {...rest}
                        label={label}
                        value={value ?? null}
                        inputFormat={inputFormat}
                        renderInput={(params) => (
                            <Stack alignItems="center" justifyContent="center" gap="4px">
                                <TextField {...params} error={!!error} fullWidth />

                                {error?.message && (
                                    <HelperText message={error?.message} type="error" />
                                )}
                            </Stack>
                        )}
                    />
                )}
            />
        </LocalizationProvider>
    );
}
